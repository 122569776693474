import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import classnames from 'classnames'
import React from 'react'
import { SelfieResponseItem } from '../../@types/api'
import { Mapper } from '../../utils/mapper'
import MarkupedImage, { IBbox } from '../MarkupedImage/MarkupedImage'
import style from './Selfie.module.css'
import { useTranslation } from 'react-i18next'
import MessageBox from '../MessageBox/MessageBox'

interface Props {
  images: File[]
  items: SelfieResponseItem[]
}

export default function Selfie({ items, images }: Props) {
  const { t } = useTranslation()
  const bboxes = items.reduce((accum: IBbox[], item: SelfieResponseItem) => {
    const faces = item.faces.map((f) => ({
      rCoords: f.coords_relative,
      coords: f.coords,
      hint: `${t('Confidence')}: ${f.confidence.toFixed(2)}`,
    }))
    const facesOnDocument = item.faces_on_document.map((f) => ({
      rCoords: f.coords_relative,
      coords: f.coords,
      hint: `${t('Confidence')}: ${f.confidence.toFixed(2)}`,
    }))

    return [...accum, ...faces, ...facesOnDocument]
  }, [])

  return (
    <div className={style.root}>
      <Container>
        {items.map((item, index) => (
          <Grid container spacing={4} key={index}>
            <Grid item xs={12} md={6}>
              <MarkupedImage
                img={URL.createObjectURL(images[0])}
                bboxes={bboxes}
                rotate={item.document?.rotation}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <Grid container spacing={3}>
                  {item.reason ? (
                    <Grid item xs={12} md={12}>
                      <div className={style.reason}>
                        {t('Reason')}: {item.reason}
                      </div>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} md={6}>
                        <div className={style.label}>{t('Faces')}</div>
                        <MessageBox
                          error={!item.same_face}
                          success={item.same_face}
                        >
                          {item.same_face ? t('Matched') : t('Not matched')}
                        </MessageBox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={style.label}>{t('Distance')}</div>
                        <MessageBox>
                          {item.distance ? item.distance.toFixed(2) : '1'}
                        </MessageBox>
                      </Grid>
                    </>
                  )}
                  {items[0].document?.type && (
                    <Grid item xs={12} md={12}>
                      <div className={style.label}>{t('Document type')}</div>
                      <div className={classnames(style.box)}>
                        {Mapper.mapDocType(items[0].document?.type)}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
        ))}
      </Container>
    </div>
  )
}
