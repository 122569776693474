import React, { useRef } from 'react'
import style from './ThreeDots.module.css'
import classnames from 'classnames'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  isRevealed?: boolean
  onClose?: () => void
  popupContent?: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  popover: {
    background: 'none',
    boxShadow: 'none'
  },
  paper: {
    padding: theme.spacing(1),
    background: 'none',
    boxShadow: 'none'
  }
}))

export function ThreeDots({
  popupContent,
  isRevealed,
  className,
  onClose,
  ...props
}: Props) {
  const divRef = useRef<HTMLDivElement | null>(null)
  const classes = useStyles()

  return (
    <div {...props} className={classnames(style.root, className)} ref={divRef}>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={!!isRevealed}
        anchorEl={divRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        {popupContent}
      </Popover>
      <div className={style.dot}></div>
      <div className={style.dot}></div>
      <div className={style.dot}></div>
    </div>
  )
}
