import IconButton from '@material-ui/core/IconButton'
import { TooltipProps } from '@material-ui/core/Tooltip'
import React from 'react'
import { QuestionIcon } from '../QuestionIcon/QuestionIcon'
import { Tooltip } from '../Tooltip/Tooltip'

export function QuestionTooltip(props: Omit<TooltipProps, 'children'>) {
  return (
    <Tooltip {...props}>
      <IconButton>
        <QuestionIcon />
      </IconButton>
    </Tooltip>
  )
}
