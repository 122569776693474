import { Slide, toast, ToastContainer, ToastOptions } from 'react-toastify'

const defaultOptions: ToastOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  type: 'error',
  style: {
    fontWeight: 500,
    fontFamily: 'inherit'
  },
  transition: Slide
}

export type MessageOptions = ToastOptions
export const MessageContainer = ToastContainer
export class Message {
  static error(msg: string, options?: MessageOptions) {
    toast(msg, {
      ...defaultOptions,
      ...options,
      type: 'error'
    })
  }
  static success(msg: string, options?: MessageOptions) {
    toast(msg, {
      ...defaultOptions,
      ...options,
      type: 'success'
    })
  }
}
