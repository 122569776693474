import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState, AppError, AppLanguage } from './store/types'
import { clearAppError, startGetOpenapi } from './store/actions'
import AppErrorScreen from './components/AppErrorScreen/AppErrorScreen'
import Layout from './components/Layout/Layout'
import { LocalStorage } from './utils/localStorage'
import { i18nInit } from './utils/i18n'
import { useTranslation } from 'react-i18next'
import { getAppLang } from './utils/utils'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import NotFound from './components/NotFound/NotFound'
import Helmet from 'react-helmet'
import FulltextRoute from './routes/FulltextRoute/FulltextRoute'
import PassportRoute from './routes/PassportRoute/PassportRoute'
import SelfieRoute from './routes/SelfieRoute/SelfieRoute'
import DocumentsRoute from './routes/DocumentsRoute/DocumentsRoute'
import DistanceRoute from './routes/DistanceRoute/DistanceRoute'
import InvoicesRoute from './routes/InvoicesRoute/InvoicesRoute'
import HandwrittenRoute from './routes/HandwrittenRoute/HandwrittenRoute'
import axios from 'axios'
import { MessageContainer } from '@dbrainio/shared-ui'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { version } from '../package.json'
import AntifraudRoute from './routes/AntifraudRoute/AntifraudRoute'
import AntifraudLocatedRoute from './routes/AntifraudLocatedRoute/AntifraudLocatedRoute'
import RuNerRoute from './routes/RuNerRoute/RuNerRoute'

const theme = createTheme({
  typography: {
    fontFamily: `"TT Norms", "Arial", sans-serif`,
    fontWeightRegular: 500,
  },
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log('error', error)
    return error.response
  },
)

i18nInit(getAppLang())

export default function App() {
  const dispatch = useDispatch()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const [isVisited] = useState(LocalStorage.getVisited())
  const isInitializing = useSelector((state: AppState) => state.isInitializing)
  const appError = useSelector((state: AppState) => state.appError)

  useEffect(() => {
    console.log(`version: ${version}`)
    // dispatch(startGetOpenapi())

    if (!isVisited) {
      LocalStorage.setVisited()
    }
    const token = LocalStorage.getToken()
    if (token) {
      axios.defaults.headers.common = {
        authorization: `${token}`,
      }
    }
  }, [dispatch, isVisited])

  const handleReset = () => {
    document.location.replace('/')
  }

  const handleClearAppError = () => dispatch(clearAppError())

  const renderAppError = (appError: string | number) => {
    switch (appError) {
      case AppError.Forbidden:
        return (
          <AppErrorScreen
            title={t('You entered an invalid token')}
            subtitle={t('Please try again or contact the Handl team')}
            onButtonClick={handleClearAppError}
          />
        )
      case AppError.InternalServerError:
        return (
          <AppErrorScreen
            title={t('Error 500')}
            subtitle={t('error_500_description')}
            onButtonClick={handleClearAppError}
          />
        )
      case AppError.ServiceUnavailable:
        return (
          <AppErrorScreen
            title={t('Error 503')}
            subtitle={t(
              'Error 503. Please try again or contact the Handl team',
            )}
            onButtonClick={handleClearAppError}
          />
        )
      case AppError.NullResponse:
        return (
          <AppErrorScreen
            title={t('No fields found in the document')}
            subtitle={t(
              'Please choose a different type of a document. If the document type is correct, please contact the Handl team',
            )}
            onButtonClick={handleClearAppError}
          />
        )
      default:
        return (
          <AppErrorScreen
            title={typeof appError === 'number' ? `${appError}` : t(appError)}
            subtitle={t('Please contact us')}
            onButtonClick={handleClearAppError}
          />
        )
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <MessageContainer />
      <Helmet>
        <title>
          {language.toLowerCase() === AppLanguage.Ru
            ? 'Dbrain Demo'
            : 'Handl.ai Demo'}
        </title>
      </Helmet>
      <Router>
        <Layout onReset={handleReset} isLoading={isInitializing}>
          {appError ? (
            renderAppError(appError)
          ) : (
            <Switch>
              <Route path="/" exact>
                <DocumentsRoute />
              </Route>
              <Route path="/fulltext" exact>
                <FulltextRoute />
              </Route>
              <Route path="/distance" exact>
                <DistanceRoute />
              </Route>
              <Route path="/passport" exact>
                <PassportRoute />
              </Route>
              <Route path="/selfie" exact>
                <SelfieRoute />
              </Route>
              <Route path="/invoices" exact>
                <InvoicesRoute />
              </Route>
              <Route path="/handwritten" exact>
                <HandwrittenRoute />
              </Route>
              <Route path="/anti-fraud" exact>
                <AntifraudRoute />
              </Route>
              <Route path="/anti-fraud-located" exact>
                <AntifraudLocatedRoute />
              </Route>
              <Route path="/ru-ner" exact>
                <RuNerRoute />
              </Route>
              <Route component={NotFound} />
            </Switch>
          )}
        </Layout>
      </Router>
    </ThemeProvider>
  )
}
