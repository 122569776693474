import React, { useState } from 'react'
import style from './PassportCheck.module.css'
import Container from '../Container/Container'
import { RecognizedFields } from '../../@types/api'
import RecognizedGrid from '../RecognizeStats/RecognizeGrid'
import { Mapper } from '../../utils/mapper'
import MagnifyImage from '../MagnifyImage/MagnifyImage'
import { Button, ButtonTheme, TextInput } from '@dbrainio/shared-ui'
import {
  IdxApi,
  VerifyPhoneResponse,
  VerifyPassportResponse,
} from '../../api/idxApi'
import classnames from 'classnames'
import MaskedInput from 'react-text-mask'
import { useTheme, useMediaQuery } from '@material-ui/core'

export interface PassportCheckProps {
  fields: RecognizedFields
  images: File[]
}

const Refresh = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
    >
      <circle cx="24" cy="24" r="24" fill="#1356A8" />
      <path d="M12 30L7 24L17 24L12 30Z" fill="#FAFAFA" />
      <path
        d="M32.7155 14.8161C30.3328 12.3756 27.096 11 23.7157 11V13C26.5499 13 29.2729 14.153 31.2845 16.2133L32.7155 14.8161ZM23.7157 11C20.3355 11 17.0986 12.3756 14.7159 14.8161L16.147 16.2133C18.1585 14.153 20.8816 13 23.7157 13V11ZM14.7159 14.8161C12.334 17.2559 11 20.5598 11 24H13C13 21.075 14.1347 18.2745 16.147 16.2133L14.7159 14.8161Z"
        fill="#FAFAFA"
      />
      <path d="M36 18L41 24L31 24L36 18Z" fill="#FAFAFA" />
      <path
        d="M32.5685 32.4853L33.2841 33.1839L32.5685 32.4853ZM15.2845 33.1839C17.6672 35.6244 20.904 37 24.2843 37L24.2843 35C21.4501 35 18.7271 33.847 16.7155 31.7867L15.2845 33.1839ZM24.2843 37C27.6645 37 30.9014 35.6244 33.2841 33.1839L31.853 31.7867C29.8415 33.847 27.1184 35 24.2843 35L24.2843 37ZM33.2841 33.1839C35.666 30.7442 37 27.4402 37 24L35 24C35 26.925 33.8653 29.7255 31.853 31.7867L33.2841 33.1839Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
export default function PassportCheck({
  images,
  fields: fieldsProp,
}: PassportCheckProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [checkPhone, setCheckPhone] = useState(false)
  const [fields, setFields] = useState(fieldsProp)
  const [isVerifying, setVerifying] = useState(false)
  const [isPhoneVerifying, setPhoneVerifying] = useState(false)
  const [phone, setPhone] = useState('+7')
  const [verificationResult, setVerificationResult] =
    useState<VerifyPassportResponse | null>(null)
  const [phoneVerificationResult, setPhoneVerificationResult] =
    useState<VerifyPhoneResponse | null>(null)

  const onStartVerification = async () => {
    setVerifying(true)
    const res = await IdxApi.verifyPassport({
      lastName: fields.surname.text,
      firstName: fields.first_name.text,
      midName: fields.other_names.text,
      birthDate: fields.date_of_birth.text,
      passportNumber: fields.series_and_number.text,
      issueDate: fields.date_of_issue.text,
    })
    console.log(
      '🚀 ~ file: PassportCheck.tsx ~ line 73 ~ onStartVerification ~ res',
      res,
    )

    if (res.status === 200) {
      setVerificationResult(res.data)
    }
    setVerifying(false)
  }

  const handleFieldChange = (key: string, value: string) => {
    setFields({
      ...fields,
      [key]: {
        ...fields[key],
        text: value,
      },
    })
  }
  const onStartPhoneVerification = async () => {
    setPhoneVerifying(true)
    const res = await IdxApi.verifyPhone({
      lastName: fields.surname.text,
      firstName: fields.first_name.text,
      midName: fields.other_names.text,
      phone,
      passportNumber: fields.series_and_number.text,
      birthDate: fields.date_of_birth.text,
    })

    if (res.status === 200) {
      setPhoneVerificationResult(res.data)
    }
    setPhoneVerifying(false)
  }

  return (
    <Container>
      <div className={style.root}>
        <div className={style.imageContainer}>
          {images[0].type === 'application/pdf' ? (
            <embed
              src={URL.createObjectURL(images[0])}
              width="100%"
              height="600"
              type="application/pdf"
            ></embed>
          ) : (
            <MagnifyImage
              src={URL.createObjectURL(images[0])}
              className={style.imageContainer}
            />
          )}
        </div>
        {
          <div className={style.fields}>
            <div className={style.grid}>
              <RecognizedGrid
                isMobile={isMobile}
                editable
                onChange={handleFieldChange}
                docType={'passport_main'}
                items={Mapper.mapRecognizedDataToItems(fields, 'passport_main')}
              />
            </div>
            <div className={style.buttons}>
              {verificationResult ? (
                <div className={style.result}>
                  <div onClick={() => setVerificationResult(null)}>
                    {<Refresh />}
                  </div>
                  <div
                    className={classnames({
                      [style.green]: verificationResult.score === 100,
                      [style.red]: verificationResult.score !== 100,
                    })}
                  >
                    {verificationResult.description}
                  </div>
                </div>
              ) : (
                <Button
                  theme={ButtonTheme.BlackOnWhite}
                  // onClick={() => setCheckPhone(true)}
                  isLoading={isVerifying}
                  onClick={onStartVerification}
                >
                  Проверить на действительность
                </Button>
              )}
              {phoneVerificationResult ? (
                <div className={style.result}>
                  <div onClick={() => setPhoneVerificationResult(null)}>
                    {<Refresh />}
                  </div>
                  {
                    <div
                      className={classnames({
                        [style.green]:
                          phoneVerificationResult.operationResult === 'success',
                        [style.red]:
                          phoneVerificationResult.operationResult !== 'success',
                      })}
                    >
                      {phoneVerificationResult.operationMessage}
                    </div>
                  }
                </div>
              ) : (
                <div className={style.checkPhone}>
                  {checkPhone ? (
                    <div className={style.phone}>
                      {/* <TextInput
                        value={phone}
                        onChange={(e) => setPhone(e.currentTarget.value)}
                        placeholder="+7(___)___-__-__"
                      />
                       */}
                      <MaskedInput
                        autoFocus
                        placeholder="+7 (___) ___-__-__"
                        mask={[
                          '+',
                          '7',
                          ' ',
                          '(',
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                        ]}
                        type="text"
                        onChange={(e) => setPhone(e.currentTarget.value)}
                        render={(ref, props) => (
                          <TextInput
                            inputRef={ref}
                            type="text"
                            placeholder="+7 (___) ___-__-__"
                            value={phone}
                            // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            //   this.handleInputChange(e.target.value, fieldName, rules, type)
                            // }
                            {...props}
                          />
                        )}
                      />
                      <Button
                        theme={ButtonTheme.Blue}
                        onClick={onStartPhoneVerification}
                        isLoading={isPhoneVerifying}
                      >
                        Проверить
                      </Button>
                    </div>
                  ) : (
                    <Button
                      theme={ButtonTheme.BlackOnWhite}
                      onClick={() => setCheckPhone(true)}
                    >
                      Проверить связку ФИО—Телефон
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        }
      </div>
    </Container>
  )
}
