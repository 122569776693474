import {
  AppState,
  ActionTypes,
  SET_TOKEN,
  CLASSIFY_SUCCEEDED,
  SET_APP_ERROR,
  RECOGNIZE_SUCCEEDED,
  CLEAR_APP_ERROR,
  SET_DOCUMENT_LOADING,
  GET_OPENAPI_SUCCEEDED,
  RECOGNIZE_BATCH_SUCCEEDED,
  SELFIE_SUCCEEDED,
  DISTANCE_SUCCEEDED,
  START_SELFIE,
  START_DISTANCE,
  START_GET_OPENAPI,
  RESET_APP,
  SET_NAV,
  RESPONSE_ERROR_HAPPENED,
  RECOGNIZE_ONLY_SUCCEEDED,
  START_RECOGNIZE_ONLY,
} from './types'
import { LocalStorage } from '../utils/localStorage'
import { START_CLASSIFY, START_RECOGNIZE, START_BATCH_RECOGNIZE } from './types'
import {
  INVOICE_SUCCEEDED,
  START_INVOICE,
  START_FULLTEXT,
  COMPLETE_FULLTEXT,
} from './types'

const initialState: AppState = {
  images: [],
  token: LocalStorage.getToken() || '',
  fulltext: null,
  isNav: false,
  selfie: null,
  distance: null,
  documents: null,
  appError: null,
  documentTypes: [],
  recognizeBatchItems: null,
  isInitializing: false,
  isButtonLoading: false,
  isRecognizeOnlyButtonLoading: false,
}

export function appReducer(
  state = initialState,
  action: ActionTypes,
): AppState {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case START_BATCH_RECOGNIZE:
      return {
        ...state,
        isButtonLoading: true,
      }
    case RESPONSE_ERROR_HAPPENED:
      return {
        ...state,
        isButtonLoading: false,
      }
    case SET_NAV:
      return {
        ...state,
        isNav: action.payload,
      }
    case RESET_APP:
      return {
        ...state,
        isNav: false,
        fulltext: null,
        selfie: null,
        distance: null,
        appError: null,
        documents: null,
        recognizeBatchItems: null,
        isButtonLoading: false,
        images: [],
      }
    case START_GET_OPENAPI:
      return {
        ...state,
        isInitializing: true,
      }
    case GET_OPENAPI_SUCCEEDED:
      return {
        ...state,
        documentTypes: action.payload,
        isInitializing: false,
      }
    case START_SELFIE:
    case START_DISTANCE:
      return {
        ...state,
        images: action.payload.files,
        isButtonLoading: true,
      }
    case START_FULLTEXT:
      return {
        ...state,
        images: action.payload,
        isButtonLoading: true,
      }
    case COMPLETE_FULLTEXT:
      return {
        ...state,
        fulltext: action.payload,
        isButtonLoading: false,
      }

    case SELFIE_SUCCEEDED:
      return {
        ...state,
        selfie: action.payload,
        isButtonLoading: false,
      }
    case DISTANCE_SUCCEEDED:
      return {
        ...state,
        distance: action.payload,
        isButtonLoading: false,
      }
    case CLASSIFY_SUCCEEDED:
      return {
        ...state,
        isButtonLoading: false,
        documents: action.payload,
      }
    case START_CLASSIFY:
      return {
        ...state,
        images: action.payload.files,
        isButtonLoading: true,
      }
    case START_RECOGNIZE:
      return {
        ...state,
      }
    case START_RECOGNIZE_ONLY:
      return {
        ...state,
        isRecognizeOnlyButtonLoading: true,
      }
    case RECOGNIZE_SUCCEEDED:
      return {
        ...state,
        isButtonLoading: false,
        documents:
          state.documents?.map((d) => {
            return d.id === action.payload.document.id
              ? {
                  ...d,
                  fields: action.payload.fields,
                }
              : d
          }) || [],
      }
    case RECOGNIZE_ONLY_SUCCEEDED:
      return {
        ...state,
        isRecognizeOnlyButtonLoading: false,
        documents: action.payload,
      }
    case INVOICE_SUCCEEDED:
      return {
        ...state,
        isButtonLoading: false,
        documents: action.payload.documents,
      }
    case START_INVOICE:
      return {
        ...state,
        isButtonLoading: true,
        images: action.payload,
      }
    case SET_APP_ERROR:
      return {
        ...state,
        appError: action.payload,
        isInitializing: false,
        isNav: false,
        isButtonLoading: false,
      }
    case CLEAR_APP_ERROR:
      return {
        ...state,
        appError: null,
      }

    case SET_DOCUMENT_LOADING:
      return {
        ...state,
        documents:
          state.documents?.map((d) => {
            return d.id === action.payload.id
              ? {
                  ...d,
                  isRecognizeLoading: action.payload.isLoading,
                }
              : d
          }) || [],
      }
    case RECOGNIZE_BATCH_SUCCEEDED:
      return {
        ...state,
        recognizeBatchItems: action.payload.items,
        isButtonLoading: false,
      }
    default:
      return state
  }
}
