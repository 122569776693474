import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  select,
} from 'redux-saga/effects'
import { Api, BatchRecognizeResponseWithFile } from '../api/api'
import {
  START_CLASSIFY,
  StartClassifyAction,
  StartRecognizeAction,
  START_RECOGNIZE,
  AppError,
  StartBatchRecognizeAction,
  START_BATCH_RECOGNIZE,
  START_GET_OPENAPI,
  StartGetOpenapiAction,
  RecognizeBatchItem,
  StartSelfieAction,
  START_SELFIE,
  START_DISTANCE,
  StartDistanceAction,
  START_INVOICE,
  StartInvoiceAction,
  START_FULLTEXT,
  START_RECOGNIZE_ONLY,
  StartRecognizeOnlyAction,
} from '../store/types'
import {
  classifySucceeded,
  recognizeSucceeded,
  setAppError,
  setDocumentLoading,
  getOpenapiSucceeded,
  recognizeBatchSucceeded,
  selfieSucceeded,
  distanceSucceeded,
  invoiceSucceeded,
  responseErrorHappened,
  recognizeOnlySucceeded,
} from '../store/actions'
import { AxiosResponse } from 'axios'
import { transformHttpErrorToAppError } from '../utils/utils'
import {
  ClassifyResponse,
  ClassifyResponseItem,
  DistanceResponse,
  OpenapiResponse,
  RecognizeResponse,
  SelfieResponse,
} from '../@types/api'
import { Mapper } from '../utils/mapper'
import { InvoiceResponse, FulltextResponse } from '../@types/api'
import { AppState, StartFulltextAction } from '../store/types'
import { completeFulltext } from '../store/actions'
import { Message } from '@dbrainio/shared-ui'
import i18n from 'i18next'

function* selfie({ payload }: StartSelfieAction) {
  try {
    const response: AxiosResponse<SelfieResponse> = yield call(
      Api.selfie,
      payload.files,
      payload.docType,
    )
    const { items } = response.data

    if (!items || items.length === 0) {
      yield put(setAppError(AppError.NullResponse))
      return
    }
    yield put(selfieSucceeded(items))
  } catch (e: any) {
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* distance({ payload }: StartDistanceAction) {
  try {
    const response: AxiosResponse<DistanceResponse> = yield call(
      Api.distance,
      payload.files,
    )
    const { items } = response.data

    if (!items || items.length === 0) {
      yield put(setAppError(AppError.NullResponse))
      return
    }
    yield put(distanceSucceeded(items))
  } catch (e: any) {
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* invoice({ payload }: StartInvoiceAction) {
  try {
    const response: AxiosResponse<InvoiceResponse> = yield call(
      Api.invoice,
      payload[0],
    )
    if (response.status === 200) {
      const { items } = response.data

      if (!items || items.length === 0) {
        yield put(setAppError(AppError.NullResponse))
        return
      }

      const fields = items[0].fields

      if (!fields) {
        throw new Error('fields is undefined')
      }

      yield put(
        invoiceSucceeded({
          documents: items,
        }),
      )
    } else {
      Message.error(`${i18n.t('Failed with')} ${response.status}`)
      yield put(responseErrorHappened())
    }
  } catch (e: any) {
    console.log('here')
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* classify({ payload }: StartClassifyAction) {
  try {
    const responses: AxiosResponse<ClassifyResponse>[] = yield all(
      payload.files.map((p) => {
        return call(Api.classify, p, payload.params)
      }),
    )

    const failed = responses.filter((r) => r.status !== 200)

    if (failed.length) {
      yield put(setAppError(transformHttpErrorToAppError(failed[0])))
      return
    }

    const items = responses.reduce(
      (accum: ClassifyResponseItem[], r: AxiosResponse<ClassifyResponse>) => {
        return [...accum, ...r.data.items]
      },
      [],
    )

    if (!items || items.length === 0) {
      yield put(setAppError(AppError.NullResponse))
      return
    }

    yield put(
      classifySucceeded(Mapper.mapClassifyResponseToDocumentInfo(items)),
    )
  } catch (e: any) {
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* recognizeOnly({ payload }: StartRecognizeOnlyAction) {
  try {
    const response: AxiosResponse<RecognizeResponse> = yield call(
      Api.recognizeFile,
      payload.files[0],
    )

    yield put(
      recognizeOnlySucceeded(
        response.data.items.map((item) => ({
          id: response.data.items[0].doc_type,
          docType: response.data.items[0].doc_type,
          coords: [],
          crop: URL.createObjectURL(payload.files[0]),
          fields: Mapper.mapRecognizeFieldsResponse(
            response.data.items[0].fields,
          ),
        })),
      ),
    )
  } catch (e: any) {
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* recognize(action: StartRecognizeAction) {
  try {
    const {
      payload: {
        document: { id, coords },
        params,
      },
    } = action
    yield put(
      setDocumentLoading({
        id,
        isLoading: true,
      }),
    )

    const response: AxiosResponse<RecognizeResponse> = yield call(
      Api.recognize,
      {
        docType: action.payload.document.docType,
        crop: action.payload.document.crop,
        withHitl: action.payload.withHitl,
        coords,
        params,
        images: action.payload.images,
      },
    )

    if (response.status === 200) {
      const { items } = response.data

      if (!items || items.length === 0) {
        yield put(setAppError(AppError.NullResponse))
        return
      }

      const fields = items[0].fields

      yield put(
        recognizeSucceeded({
          document: action.payload.document,
          fields: Mapper.mapRecognizeFieldsResponse(fields),
        }),
      )
    } else {
      yield put(setAppError(transformHttpErrorToAppError(response)))
    }

    yield put(
      setDocumentLoading({
        id: action.payload.document.id,
        isLoading: false,
      }),
    )
  } catch (e: any) {
    console.log('e', e)
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* fulltext({ payload }: StartFulltextAction) {
  const token: string = yield select((state: AppState) => state.token)

  try {
    const response: AxiosResponse<FulltextResponse> = yield call(
      Api.fulltext,
      payload[0],
      token,
    )

    const { items } = response.data

    if (!items || items.length === 0) {
      yield put(setAppError(AppError.NullResponse))
      return
    }

    yield put(completeFulltext(items))
  } catch (e: any) {
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* batchRecognize(action: StartBatchRecognizeAction) {
  const responses: BatchRecognizeResponseWithFile[] = yield call(
    Api.batchRecognize,
    action.payload,
  )

  const items: RecognizeBatchItem[] = responses.reduce(
    (accum: RecognizeBatchItem[], next: BatchRecognizeResponseWithFile) => {
      const items = next.response?.data.items?.map((item) => ({
        docType: item.doc_type,
        fields: Mapper.mapRecognizeFieldsResponse(item.fields),
        filename: next.file.name,
      })) as RecognizeBatchItem[]

      return [
        ...accum,
        ...(items || [
          {
            fields: undefined,
            file: next.file,
            filename: next.file.name,
            rejected: next.status === 'rejected',
            statusCode: next.response?.status,
          },
        ]),
      ]
    },
    [],
  )

  yield put(
    recognizeBatchSucceeded({
      items,
    }),
  )
}

function* getOpenapi(action: StartGetOpenapiAction) {
  try {
    const response: OpenapiResponse = yield call(Api.getOpenapi)
    if (!response || !response.data) {
      yield put(setAppError('Openapi is not available. Try later'))
      return
    }

    yield put(
      getOpenapiSucceeded(response.data.components.schemas.DocumentTypes.enum),
    )
  } catch (e: any) {
    yield put(setAppError(transformHttpErrorToAppError(e)))
  }
}

function* AppSaga() {
  yield takeLatest(START_CLASSIFY, classify)
  yield takeLatest(START_RECOGNIZE_ONLY, recognizeOnly)
  yield takeLatest(START_INVOICE, invoice)
  yield takeLatest(START_SELFIE, selfie)
  yield takeLatest(START_DISTANCE, distance)
  yield takeLatest(START_FULLTEXT, fulltext)
  yield takeEvery(START_RECOGNIZE, recognize)
  yield takeLatest(START_BATCH_RECOGNIZE, batchRecognize)
  yield takeLatest(START_GET_OPENAPI, getOpenapi)
}

export default AppSaga
