import { TooltipProps } from '@material-ui/core/Tooltip'
import { default as MuiTooltip } from '@material-ui/core/Tooltip'

import React from 'react'
import style from './Tooltip.module.css'

export function Tooltip(props: TooltipProps) {
  const tooltipProps: Partial<TooltipProps> = {
    enterTouchDelay: 0.2,
    className: style.tooltip,
    arrow: true,
    classes: { tooltip: style.tooltipContent, arrow: style.arrow }
  }

  return <MuiTooltip {...tooltipProps} {...props} />
}
