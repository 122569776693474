import React, { useState } from 'react'
import style from './MultifileUpload.module.css'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { cutFilenameWithEllipsis } from '../../utils/utils'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Config } from '../../config/config'
import { useEffect } from 'react'
import AdvancedParams, {
  IAdvancedParams,
} from '../AdvancedParams/AdvancedParams'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/types'
import { Button, ButtonTheme } from '@dbrainio/shared-ui'

export interface MultifileUploadProps {
  batchButtonText: string
  error?: string
  disabled?: boolean
  isButtonLoading?: boolean
  isAdvancedParams?: boolean
  showRecognizeOnly?: boolean
  onSubmit: (
    acceptedFiles: File[],
    params: IAdvancedParams,
    recognizeOnly?: boolean,
  ) => void
  onDrop: (acceptedFiles: File[]) => void
}

const MultifileUpload = (props: MultifileUploadProps) => {
  const matchesMobile = useMediaQuery('(max-width:600px)')
  const [params, setParams] = useState<IAdvancedParams>({})

  const { t } = useTranslation()

  const [fileList, setFileList] = useState<File[]>([])

  const handleButtonClick = () => {
    props.onSubmit(fileList, params)
  }

  const onRecognizeOnly = () => {
    props.onSubmit(fileList, params, true)
  }

  const isReduxButtonLoading = useSelector(
    (state: AppState) => state.isButtonLoading,
  )
  const isRecognizeOnlyButtonLoading = useSelector(
    (state: AppState) => state.isRecognizeOnlyButtonLoading,
  )

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        fileList.length && props.onSubmit(fileList, params)
      }
    }

    document.addEventListener('keypress', handleKeyPress)
    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, params])

  const onDrop = (acceptedFiles: File[]) => {
    props.onDrop(acceptedFiles)
    setFileList(acceptedFiles.slice(0, Config.RecognizeDocumentsLimit))
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: 'image/jpeg, image/png, .bmp, .tiff, .gif, .djvu, .pdf, .tif',
      maxSize: 10 * 1024 * 1024,
    })

  const dropzoneClasses = [style.dropzone]

  if (isDragActive) {
    dropzoneClasses.push(style.isDragActive)
  }

  const deleteFile = (name: string) =>
    setFileList(fileList.filter((f) => f.name !== name))

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const renderDeleteIcon = () => (
    <div className={style.deleteIcon}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.000435925 6.66666L1.33377 7.99999L4.00016 5.3336L6.66666 8.00009L7.99999 6.66676L5.33349 4.00027L8.00043 1.33333L6.6671 0L4.00016 2.66693L1.33333 0.000101436L0 1.33343L2.66683 4.00026L0.000435925 6.66666Z"
          fill="#FAFAFA"
        />
      </svg>
    </div>
  )

  const renderFileList = () => {
    return (
      <div className={style.fileList}>
        <div className={style.fileListHeader}>
          <div className={style.fileListHeaderItem}>{t('File')}</div>
          <div className={style.fileListHeaderItem}>{t('Size')}</div>
        </div>
        {fileList.map(({ name, size, type }, index) => (
          <div className={style.fileListItem} key={index}>
            <div className={style.name}>
              {cutFilenameWithEllipsis(name, matchesMobile ? 10 : 30, type)}
            </div>
            <div>{formatBytes(size)}</div>
            <div className={style.deleteFile} onClick={() => deleteFile(name)}>
              {renderDeleteIcon()}
            </div>
          </div>
        ))}
        {fileRejections.map(({ file, errors }, index) => (
          <div
            className={[style.fileListItem, style.rejected].join(' ')}
            key={index}
          >
            <div>
              {cutFilenameWithEllipsis(
                file.name,
                matchesMobile ? 10 : 30,
                file.type,
              )}
            </div>
            <div>{formatBytes(file.size)}</div>
            <div
              className={style.deleteFile}
              onClick={() => deleteFile(file.name)}
            >
              {/* {renderDeleteIcon()} */}
            </div>
          </div>
        ))}
        {props.isAdvancedParams && <AdvancedParams onChange={setParams} />}
        <div className={style.footer}>
          <div className={style.buttons}>
            <Button
              theme={ButtonTheme.Blue}
              onClick={handleButtonClick}
              disabled={props.disabled}
              isLoading={props.isButtonLoading || isReduxButtonLoading}
            >
              {t(
                fileList.length < Config.BatchRecognizeThreshold
                  ? props.batchButtonText
                  : 'Recognize',
              )}
            </Button>
            {fileList.length === 1 && props.showRecognizeOnly && (
              <Button
                theme={ButtonTheme.Blue}
                onClick={onRecognizeOnly}
                disabled={props.disabled}
                isLoading={isRecognizeOnlyButtonLoading}
              >
                {t('Recognize only')}
              </Button>
            )}
          </div>
          <div className={style.error}>
            {props.error && (
              <div className={style.sizeExceededHint}>{t(props.error)}</div>
            )}
            {fileRejections.length === 1 && (
              <div className={style.sizeExceededHint}>
                {t(
                  'There is file with size more than 10mb, that will not be processed',
                )}
              </div>
            )}
            {fileRejections.length > 1 && (
              <div className={style.sizeExceededHint}>
                {t(
                  'There are files with size more than 10mb, that will not be processed',
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
  const rootProps = getRootProps({ className: style.dropzone })

  return (
    <section className={style.root}>
      {fileList.length ? (
        renderFileList()
      ) : (
        <div>
          <div {...rootProps}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className={style.title}>ОК</div>
            ) : (
              <>
                <div className={style.title}>{t('Choose a file(s)')}</div>
                <div className={style.subtitle}>
                  {t('or just drag and drop it here')}
                </div>
              </>
            )}
          </div>
          <div className={style.guide}>
            {t('up to 50 pieces')}
            .
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'Multipage files and several documents in one file are supported',
                ),
              }}
            ></span>
            .
          </div>
        </div>
      )}
    </section>
  )
}

export default MultifileUpload
