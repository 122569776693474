import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import classnames from 'classnames'
import React from 'react'
import { DistanceResponseItem } from '../../@types/api'
import style from './Distance.module.css'
import { useTranslation } from 'react-i18next'
import MarkupedImage, { IBbox } from '../MarkupedImage/MarkupedImage'
import { useEffect } from 'react'

interface Props {
  images: File[]
  items: DistanceResponseItem[]
}

export default function Distance({ items, images }: Props) {
  const { t } = useTranslation()

  useEffect(() => {
    if (images.length < 2) {
      throw new Error(
        `Something gone wrong with images number:${images.length}`,
      )
    }
  }, [images.length])

  const bboxes1 = items.reduce((accum: IBbox[], item: DistanceResponseItem) => {
    const faces = item.faces1.map((f) => ({
      rCoords: f.coords_relative,
      coords: f.coords,
      hint: `${t('Confidence')}: ${f.confidence.toFixed(2)}`,
    }))
    return [...accum, ...faces]
  }, [])

  const bboxes2 = items.reduce((accum: IBbox[], item: DistanceResponseItem) => {
    const faces = item.faces2.map((f) => ({
      rCoords: f.coords_relative,
      coords: f.coords,
      hint: `${t('Confidence')}: ${f.confidence.toFixed(2)}`,
    }))
    return [...accum, ...faces]
  }, [])

  return (
    <div className={style.root}>
      <Container>
        {items.map((item, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={12} md={8}>
              <div className={style.imageContainer}>
                <MarkupedImage
                  img={URL.createObjectURL(images[0])}
                  bboxes={bboxes1}
                />
                <MarkupedImage
                  img={URL.createObjectURL(images[1])}
                  bboxes={bboxes2}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <div className={style.label}>{t('Faces')}</div>
                    <div
                      className={classnames(style.box, style.green, {
                        [style.red]: !item.same_face,
                      })}
                    >
                      {item.same_face ? t('Matched') : t('Not matched')}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className={style.label}>{t('Distance')}</div>
                    <div className={classnames(style.box)}>
                      {item.distance.toFixed(2)}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        ))}
      </Container>
    </div>
  )
}
