import style from './Textarea.module.css'
import React from 'react'

export enum TextareaTheme {
  White = 'White',
  WhiteOnBlue = 'WhiteOnBlue'
}

export type TextareaProps = {
  theme?: TextareaTheme
} & React.HTMLProps<HTMLTextAreaElement>

export function Textarea(props: TextareaProps) {
  const getTextInputClassByTheme = (theme?: TextareaTheme) => {
    switch (theme) {
      case TextareaTheme.WhiteOnBlue:
        return style.whiteOnBlue
      case TextareaTheme.White:
      default:
        return style.white
    }
  }

  return (
    <div className={style.root}>
      <textarea
        {...props}
        className={[style.input, getTextInputClassByTheme(props.theme)].join(
          ' '
        )}
      ></textarea>
    </div>
  )
}

export default Textarea
