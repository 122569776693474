import React, { useEffect } from 'react'
import Grow from '@material-ui/core/Grow'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import Fade from '@material-ui/core/Fade'
import { isiOS } from '../../utils/utils'

export enum PopupEffect {
  Standard = 'Standard',
  Fade = 'Fade',
}

export interface PopupProps {
  isOn: boolean
  children: React.ReactElement<any, any>
  effect?: PopupEffect
}

export default function Popup(props: PopupProps) {
  const iOS = isiOS()

  useEffect(() => {
    if (!iOS) {
      if (props.isOn) {
        disableBodyScroll(window.document.body)
      } else {
        enableBodyScroll(window.document.body)
      }
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  })

  return props.effect === PopupEffect.Fade ? (
    <Fade in={props.isOn}>{props.children}</Fade>
  ) : (
    <Grow in={props.isOn}>{props.children}</Grow>
  )
}
