import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import MarkupedImage from '../MarkupedImage/MarkupedImage'
import style from './RuNer.module.css'
import { useTranslation } from 'react-i18next'
import MessageBox from '../MessageBox/MessageBox'
import { RuNerResult } from '../../@types/api'

interface Props {
  images: File[]
  result: RuNerResult[]
}

const Word = ({
  word,
  ...divProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { word: string }) => {
  return (
    <div className={style.word} {...divProps}>
      {word}
    </div>
  )
}

export default function RuNer({ result, images }: Props) {
  const { t } = useTranslation()
  const [hover, setHover] = useState<string | null>(null)

  return (
    <div className={style.root}>
      <Container>
        <div className={style.items}>
          {result.map((item, index) => (
            <div className={style.item}>
              <Grid container spacing={4} key={index}>
                <Grid item xs={12} md={8}>
                  <div className={style.image}>
                    {item.src_image && (
                      <MarkupedImage
                        img={item.src_image}
                        bboxes={
                          window._env_.DISABLE_RUNER_BBOXES
                            ? []
                            : item.entities?.map((ent) => ({
                                coords: ent.coords,
                                hint: ent.confidence,
                                isHighlighted: ent.id === hover,
                              })) || []
                        }
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className={style.label}>{t('Result')}</div>
                  {item.entities.length ? (
                    <div className={style.grid}>
                      {item.entities.map((w, index) => (
                        <div className={style.row}>
                          <div className={style.entityName}>
                            <strong> {w.entity_name}</strong> • {w.confidence}
                          </div>
                          <Word
                            word={w.text}
                            key={index}
                            onMouseEnter={() => setHover(w.id || null)}
                            onMouseLeave={() => setHover(null)}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <MessageBox error>Слов не найдено</MessageBox>
                  )}
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}
