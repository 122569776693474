import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import MarkupedImage from '../MarkupedImage/MarkupedImage'
import style from './AntiFraud.module.css'
import { useTranslation } from 'react-i18next'
import MessageBox from '../MessageBox/MessageBox'
import { FraudResult } from '../../@types/api'

interface Props {
  images: File[]
  result: FraudResult[]
}

export default function AntiFraud({ result, images }: Props) {
  const { t } = useTranslation()

  return (
    <div className={style.root}>
      <Container>
        {result.map((item, index) => (
          <Grid container spacing={4} key={index}>
            <Grid item xs={12} md={8}>
              <div className={style.images}>
                <MarkupedImage
                  img={item.src_image}
                  bboxes={
                    item.regions?.map((r) => ({
                      coords: r.coords,
                      hint: r.score,
                    })) || []
                  }
                />
                <MarkupedImage img={item.ela_image} bboxes={[]} />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div>
                <Grid container spacing={3}>
                  <>
                    <Grid item xs={12} md={6}>
                      <div className={style.label}>{t('Result')}</div>
                      <MessageBox
                        error={item.fraud_class !== 'not fraud'}
                        success={item.fraud_class === 'not fraud'}
                      >
                        {item.fraud_class === 'not fraud'
                          ? t('Not fraud')
                          : t('Fraud')}
                      </MessageBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className={style.label}>{t('Confidence')}</div>
                      <MessageBox>
                        {item.score ? item.score.toFixed(2) : '1'}
                      </MessageBox>
                    </Grid>
                  </>
                </Grid>
              </div>
            </Grid>
          </Grid>
        ))}
      </Container>
    </div>
  )
}
