import { useEffect } from 'react'
import style from './BatchToCsvStage.module.css'
import Container from '../Container/Container'
import { useTranslation } from 'react-i18next'
import { RecognizeBatchItem } from '../../store/types'
import { downloadCsv } from '../../utils/utils'
import Grid from '@material-ui/core/Grid'
import { Button, ButtonTheme } from '@dbrainio/shared-ui'

export interface BatchToCsvStageProps {
  onReturnClick: () => void
  recognized: RecognizeBatchItem[]
}

export default function BatchToCsvStage({
  onReturnClick,
  recognized,
}: BatchToCsvStageProps) {
  const { t } = useTranslation()

  const handleDownload = () => {
    const rows = recognized.reduce(
      (accum: string[][], next: RecognizeBatchItem) => {
        const fields =
          next.fields !== undefined
            ? Object.keys(next.fields).reduce(
                (accum: string[][], f: string) => {
                  return [
                    ...accum,
                    [
                      next.filename,
                      next.docType,
                      f,
                      (next.fields && next.fields[f].text.replace(',', '')) ||
                        '',
                      (next.fields && next.fields[f].confidence + '') || '',
                    ],
                  ]
                },
                [],
              )
            : [[next.filename, 'ОШИБКА!', 'ОШИБКА!']]

        return [...accum, ...fields]
      },
      [
        [
          'Имя файла',
          'Тип документа',
          'Название поля',
          'Текст в поле',
          'Уверенность',
        ],
      ],
    )

    downloadCsv({
      rows,
      filename: 'RecognizedData_' + Date.now() + '.csv',
    })
  }

  useEffect(() => {
    handleDownload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={style.root}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <h2 className={style.heading}>Готово!</h2>
            <div className={style.text}>
              Результаты будут выгружены в csv-таблице. <br />
              Если этого не произошло, скачайте по{' '}
              <button onClick={handleDownload} className={style.download}>
                ссылке
              </button>
              .
            </div>
            <Button theme={ButtonTheme.BlackOnWhite} onClick={onReturnClick}>
              {t('Go back')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
