import classNames from 'classnames'
import React from 'react'
import style from './Checkbox.module.css'

export enum CheckboxTheme {
  White = 'White',
  Gray = 'Gray',
  Black = 'Black',
  Checkmark = 'Checkmark'
}

export interface CheckboxProps {
  isChecked: boolean
  label?: string
  theme?: CheckboxTheme
  className?: string
  centered?: boolean
  onChange: (value: boolean) => void
}

export function Checkbox({
  onChange,
  isChecked,
  centered,
  className,
  label,
  theme = CheckboxTheme.Black
}: CheckboxProps) {
  const handleChange = () => onChange(!isChecked)
  const isCheckmark = theme === CheckboxTheme.Checkmark

  const renderCheckmark = () => (
    <svg
      width='16'
      height='13'
      viewBox='0 0 16 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={style.svgCheckmark}
    >
      <path d='M2 5L6.5 10L14 1' stroke='#111111' stroke-width='3' />
    </svg>
  )

  return (
    <div
      className={classNames(style.container, className, {
        [style.centered]: centered,
        [style.checkmarkContainer]: theme === CheckboxTheme.Checkmark
      })}
    >
      <div
        className={classNames(style.root, {
          [style.gray]: theme === CheckboxTheme.Gray,
          [style.white]: theme === CheckboxTheme.White,
          [style.black]: theme === CheckboxTheme.Black,
          [style.checkmark]: theme === CheckboxTheme.Checkmark,
          [style.relative]: !!label
        })}
        onClick={handleChange}
      >
        {isChecked &&
          (isCheckmark ? renderCheckmark() : <div className={style.box} />)}
      </div>
      {label && (
        <span className={style.label} onClick={handleChange}>
          {label}
        </span>
      )}
    </div>
  )
}
