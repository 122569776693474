import React, { FormEvent, useState } from 'react'
import style from './UploadStage.module.css'
import MultifileUpload from '../MultifileUpload/MultifileUpload'
import Heading from '../Heading/Heading'
import Container from '../Container/Container'
import { useTranslation } from 'react-i18next'
import Tutorial from '../Tutorial/Tutorial'
import { Mapper, NO_DOCUMENT } from '../../utils/mapper'
import Grid from '@material-ui/core/Grid'
import Select from '../Select/Select'
import { AppLanguage } from '../../store/types'
import { IAdvancedParams } from '../AdvancedParams/AdvancedParams'
import { useLocation } from 'react-router'
import { LocalStorage } from '../../utils/localStorage'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { setToken } from '../../store/actions'
import { TextInput, TextInputTheme } from '@dbrainio/shared-ui'

export interface UploadStageProps {
  token: string
  docTypes: string[]
  isTutorialOpen?: boolean
  isButtonLoading?: boolean
  showRecognizeOnly?: boolean
  onDrop: (
    acceptedFiles: File[],
    docType: string,
    params: IAdvancedParams,
    isRecognizeOnly?: boolean,
  ) => void
}

const UploadStage = ({
  onDrop,
  isTutorialOpen,
  token,
  docTypes,
  showRecognizeOnly,
  isButtonLoading,
}: UploadStageProps) => {
  const [selected, setSelected] = useState(
    docTypes.includes('passport_main') ? 'passport_main' : docTypes[0],
  )
  const [touched, setTouched] = useState(false)

  const l = useLocation()
  const dispatch = useDispatch()
  const [fileList, setFileList] = useState<File[]>([])

  const {
    t,
    i18n: { language },
  } = useTranslation()
  const isRu = language.toLowerCase() === AppLanguage.Ru

  const mapDocumentTypes = (documentTypes: string[]) => {
    return [NO_DOCUMENT, ...documentTypes].map((val: string) => ({
      value: val,
      text: isRu ? Mapper.mapDocType(val) : val,
    }))
  }

  const renderSelect = () => (
    <div className={style.selectContainer}>
      <div className={style.selectLabel}>{t('Document type')}</div>
      <Select
        className={style.select}
        selected={selected}
        onChange={setSelected}
        items={mapDocumentTypes(docTypes)}
      />
    </div>
  )
  const onTokenChange = (token: string) => {
    dispatch(setToken(token))
    LocalStorage.setToken(token)
    axios.defaults.headers.common = {
      authorization: `${token}`,
    }
  }

  const getTokenError = () => {
    if (!token.length) {
      return t('Empty token')
    }

    if (token.match(/[^\x20-\x7E]+/g) && token.length > 0) {
      return t('Wrong token format')
    }
  }

  return (
    <div>
      <div className={style.tokenSection}>
        <div className={style.tokenForm}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={8}>
                <Heading weight={4}>{t('Enter your token')}</Heading>
                <TextInput
                  placeholder=""
                  focus
                  theme={TextInputTheme.White}
                  type="password"
                  required
                  onBlur={() => setTouched(true)}
                  error={
                    touched || fileList.length > 0 ? getTokenError() : undefined
                  }
                  value={token}
                  onChange={(event: FormEvent<HTMLInputElement>) => {
                    onTokenChange(event.currentTarget.value.slice(0, 100))
                    setTouched(true)
                  }}
                  autoComplete="no"
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <div className={style.tutorial}>
        <Container>
          {isRu && <Tutorial isInitiallyOpen={isTutorialOpen} />}
        </Container>
      </div>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12}>
            {l.pathname === '/selfie' && renderSelect()}
          </Grid>
          <MultifileUpload
            showRecognizeOnly={showRecognizeOnly}
            batchButtonText={l.pathname === '/' ? 'Classify' : 'Recognize'}
            disabled={!!getTokenError()}
            error={
              l.pathname === '/distance' && fileList.length < 2
                ? t('Number of image >= 2')
                : undefined
            }
            onDrop={setFileList}
            onSubmit={(files, params, isRecognizeOnly) => {
              onDrop(files, selected, params, isRecognizeOnly)
            }}
            isButtonLoading={isButtonLoading}
            isAdvancedParams={l.pathname === '/' || l.pathname === '/documents'}
          />
        </Grid>
      </Container>
    </div>
  )
}

export default UploadStage
