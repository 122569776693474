import classnames from 'classnames'
import React, { useState } from 'react'
import style from './Pagination.module.css'

export enum PaginationTheme {
  Default = 'Default'
}

export interface PaginationProps {
  theme?: PaginationTheme
  initialPage?: number
  total: number
  onChange: (page: number) => void
}

export function Pagination({ initialPage, total, onChange }: PaginationProps) {
  const [current, setCurrent] = useState(initialPage || 1)

  const pages = Array(Math.ceil(total))
    .fill(0)
    .map((_p, idx) => idx + 1)

  const onPageChange = (page: number) => {
    if (page === 0) return
    if (page === current) return
    if (page > total) return

    onChange(page)
    setCurrent(page)
  }

  const renderItem = (page: number) => {
    const isActive = current === page

    return (
      <div
        onClick={() => onPageChange(page)}
        className={classnames(style.item, {
          [style.active]: isActive
        })}
        key={page}
      >
        {page}
      </div>
    )
  }

  const renderArrow = () => (
    <svg
      width='36'
      height='12'
      viewBox='0 0 36 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='36'
        y='7'
        width='26'
        height='2'
        transform='rotate(-180 36 7)'
        fill='#111111'
      />
      <path
        d='M7.86805e-07 6L10 1.31134e-06L10 12L7.86805e-07 6Z'
        fill='#111111'
      />
    </svg>
  )

  return (
    <div className={style.root}>
      {current > 1 && (
        <div className={style.arrow} onClick={() => onPageChange(current - 1)}>
          {renderArrow()}
        </div>
      )}
      {pages
        .slice(Math.max(current - 2, 0), Math.min(current + 3, total))
        .map((idx) => renderItem(idx))}
      {current < total - 4 && <div className={style.dots}>...</div>}
      {current < total - 3 && renderItem(total)}
      {current < total && (
        <div
          className={classnames(style.arrow, style.right)}
          onClick={() => onPageChange(current + 1)}
        >
          {renderArrow()}
        </div>
      )}
    </div>
  )
}
